import React, { useEffect, useMemo } from 'react'
import WorkflowSidebar from './workflow-sidebar'
import { selectDocumentViewerSidebarOpen } from '../redux/viewer-slice'
import { useSelector, useDispatch } from 'react-redux'
import DocumentViewerSidebar from './document-viewer-sidebar'
import {
  selectWorkflowSidebarOpen,
  selectCurrentProject,
  setCurrentDocument,
  setProjectSidebarOpen,
} from '../redux/application-slice'
import { useTabWidth } from '../hooks/use-tab-width'
import { useParams, useSearchParams } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import {
  useGetDocumentByUuidQuery,
  useGetDocumentsListByProjectQuery,
  useGetUserProfileQuery,
} from '../redux/api-slice'
import UseUrlNavigate from '../hooks/use-url-navigate'
import { useOnlineStatus } from '../hooks/use-online-status'
import { toast } from 'react-toastify'
import { ProjectDocumentMetadata } from '../shared/interfaces/project/document/document.interface'
import { Tooltip as MantineTooltip } from '@mantine/core'
import { Tooltip } from 'react-tooltip'
import DocumentSidebar from './document-sidebar'
import useWindowDimensions from '../hooks/use-window-dimensions'
/**
 * Home component
 */
const Home: React.FC = () => {
  const { width } = useWindowDimensions()
  const documentViewerSidebarOpen = useSelector(selectDocumentViewerSidebarOpen)
  const workflowSidebarOpen = useSelector(selectWorkflowSidebarOpen)
  const { tabWidth, handler, setDefaultTabWidth } = useTabWidth()
  const { projectId, documentId } = useParams()
  const currentProject = useSelector(selectCurrentProject)
  const [searchParams] = useSearchParams()
  const { data: profile } = useGetUserProfileQuery(undefined)
  const searchQuery = useMemo(() => {
    return searchParams.get('search') ?? undefined
  }, [searchParams])
  const { currentData: documents } = useGetDocumentsListByProjectQuery(
    currentProject?.id
      ? {
          projectId: currentProject?.id,
          search_query: searchQuery,
        }
      : skipToken
  )
  const urlNavigate = UseUrlNavigate()
  const dispatch = useDispatch()
  const online = useOnlineStatus()

  useEffect(() => {
    setDefaultTabWidth()
  }, [setDefaultTabWidth])

  useEffect(() => {
    if (projectId || !profile?.agreed_tandcs) {
      return
    }
    dispatch(setProjectSidebarOpen(true))
  }, [dispatch, projectId, profile?.agreed_tandcs])

  const initialDocument: ProjectDocumentMetadata | null = useMemo(() => {
    if (!documents) {
      return null
    }
    const v1Documents: {
      [id: string]: ProjectDocumentMetadata
    } = {}
    for (const document of documents.filter((d) => !d.folder)) {
      if (document.v1_document) {
        v1Documents[document.v1_document] = document
      }
    }
    return documents.filter(
      (document) => !v1Documents[document?.id?.toString()]
    )?.[0]
  }, [documents])

  const { currentData: firstDocument } = useGetDocumentByUuidQuery(
    initialDocument?.uuid ?? skipToken
  )

  useEffect(() => {
    if (!projectId) {
      return
    }
    if (!documentId) {
      if (!firstDocument?.uuid) {
        return
      }
      if (currentProject?.uuid !== projectId) {
        return
      }
      dispatch(setCurrentDocument(firstDocument))
      urlNavigate.navigateDocumentUrl(firstDocument.uuid, {
        replace: true,
      })
    }
  }, [
    projectId,
    documentId,
    firstDocument,
    currentProject,
    urlNavigate,
    dispatch,
  ])

  useEffect(() => {
    if (!online) {
      toast.error('You are offline. Check your network connection.', {
        toastId: 'offline',
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        hideProgressBar: true,
        pauseOnHover: false,
        position: 'top-right',
        closeButton: false,
        className: 'bg-gray-500 text-gray-100',
      })
    } else {
      toast.dismiss('offline')
    }
  }, [online])

  const documentViewerWidth = useMemo(() => {
    return workflowSidebarOpen ? width - tabWidth - 10 : '100%'
  }, [workflowSidebarOpen, tabWidth, width])

  const workflowSidebarWidth = useMemo(() => {
    return workflowSidebarOpen ? tabWidth : 0
  }, [workflowSidebarOpen, tabWidth])

  return (
    <div
      className="flex h-full w-full overflow-hidden"
      style={{
        maxHeight: 'calc(100vh)',
      }}
    >
      <div className={`flex h-full w-full flex-1`}>
        <DocumentSidebar width={documentViewerWidth} />
      </div>
      <div className="relative flex">
        <div className="relative flex">
          {documentViewerSidebarOpen && <DocumentViewerSidebar />}
          {workflowSidebarOpen && (
            <MantineTooltip
              label="Click and Drag to Resize Window"
              position="left"
            >
              <button
                className={
                  'text-md flex cursor-col-resize select-none items-center border border-t-[0px] border-[#dbdee3]  bg-[#f2f5fb]  px-0.5 text-xs transition-colors hover:border-blue-500 hover:bg-blue-300 hover:text-blue-500'
                }
                onMouseDown={handler}
                type="button"
              >
                ≡
              </button>
            </MantineTooltip>
          )}
        </div>
        <div className={`w-full overflow-hidden`}>
          {workflowSidebarOpen && (
            <WorkflowSidebar width={workflowSidebarWidth} />
          )}
        </div>
        <Tooltip id={'document-title-single-tooltip'} style={{ zIndex: 100 }} />
        <Tooltip id={'document-title-tooltip'} style={{ zIndex: 100 }} />
      </div>
    </div>
  )
}
export default Home
