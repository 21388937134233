import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectDocumentViewerSidebarOpen,
  setDocumentViewerSidebarOpen,
} from '../../../redux/viewer-slice'
import { useParams } from 'react-router-dom'
import DocumentViewerPopover from '../document-viewer-popover'
import {
  selectCurrentDocument,
} from '../../../redux/application-slice'
import { useHotkeys } from 'react-hotkeys-hook'
import {
  BookOpenIcon,
  MagnifyingGlassIcon,
  TableCellsIcon,
} from '@heroicons/react/24/outline'
import clsx from 'clsx'
import posthog from 'posthog-js'
import { POSTHOG } from '../../../utils/posthog-constants'


interface DocumentViewerHeaderMiscProps {
  condensed?: boolean
}

const DocumentViewerHeaderMisc: React.FC<DocumentViewerHeaderMiscProps> = (
  props
) => {
  const dispatch = useDispatch()
  const currentDocument = useSelector(selectCurrentDocument)
  const { documentId, projectId } = useParams<{
    documentId: string
    projectId: string
  }>()
  const documentViewerSidebarOpen = useSelector(selectDocumentViewerSidebarOpen)
  const onClickSearch = useCallback(() => {
    posthog.capture(POSTHOG.opened_search_from_document_viewer, {
      projectId: projectId ?? '',
      documentId: documentId ?? '',
    })
    dispatch(
      setDocumentViewerSidebarOpen(
        documentViewerSidebarOpen === 'search' ? null : 'search'
      )
    )
    // eslint-disable-next-line
  }, [dispatch, documentViewerSidebarOpen])

  const onCtrlF = useCallback(
    (e) => {
      e.preventDefault()
      onClickSearch()
    },
    [onClickSearch]
  )

  const onClickToc = useCallback(() => {
    posthog.capture(POSTHOG.opened_toc_from_document_viewer, {
      projectId: projectId ?? '',
      documentId: documentId ?? '',
    })
    dispatch(
      setDocumentViewerSidebarOpen(
        documentViewerSidebarOpen === 'toc' ? null : 'toc'
      )
    )
    // eslint-disable-next-line
  }, [dispatch, documentViewerSidebarOpen])

  const onClickDefinitions = useCallback(() => {
    posthog.capture(POSTHOG.opened_definitions_from_document_viewer, {
      projectId: projectId ?? '',
      documentId: documentId ?? '',
    })
    dispatch(
      setDocumentViewerSidebarOpen(
        documentViewerSidebarOpen === 'definitions' ? null : 'definitions'
      )
    )
    // eslint-disable-next-line
  }, [dispatch, documentViewerSidebarOpen])

  useHotkeys('ctrl+f', onCtrlF)
  useHotkeys('meta+f', onCtrlF)

  return (
    <div className="mr-2 flex space-x-1 text-sm">
      <button
        disabled={!documentId}
        onClick={onClickDefinitions}
        className={clsx(
          `group rounded-lg border-2 border-[#ffffff00] px-1 py-1.5 transition-colors`,
          documentId &&
          'hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]',
          documentViewerSidebarOpen === 'definitions' && 'bg-gray-200'
        )}
      >
        <div className="flex items-center justify-center text-gray-500 group-hover:text-black">
          <BookOpenIcon className="h-4 w-4 text-gray-500 group-hover:text-black" />
          {!props.condensed && <div className="ml-2">Definitions</div>}
        </div>
      </button>

      <button
        disabled={!documentId}
        onClick={onClickSearch}
        className={`group cursor-pointer rounded-lg border-2 border-[#ffffff00] px-1 py-1.5 transition-colors  ${documentId
          ? 'hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]'
          : ''
          } ${documentViewerSidebarOpen === 'search' ? 'bg-gray-200' : ''}`}
      >
        <div className="flex items-center justify-center text-gray-500 group-hover:text-black">
          <MagnifyingGlassIcon className="h-4 w-4 text-gray-500 group-hover:text-black" />
          {!props.condensed && <div className="pl-2">Search</div>}
        </div>
      </button>

      <button
        disabled={!documentId}
        onClick={onClickToc}
        className={`group rounded-lg border-2 border-[#ffffff00] px-1 py-1.5 transition-colors ${documentId
          ? 'hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]'
          : ''
          } ${documentViewerSidebarOpen === 'toc' ? 'bg-gray-200' : ''}`}
      >
        <div className="flex items-center justify-center text-gray-500 group-hover:text-black">
          <TableCellsIcon className="h-4 w-4 text-gray-500 group-hover:text-black" />
          {!props.condensed && <div className="pl-2 whitespace-nowrap">Table of Contents</div>}
        </div>
      </button>

      {currentDocument?.v1_document ? (
        <DocumentViewerPopover document={currentDocument} />
      ) : null}
    </div>
  )
}
export default DocumentViewerHeaderMisc
