import React, { useEffect, useState, useCallback } from 'react'
import { Button, Drawer, TextInput, Skeleton } from '@mantine/core'
import { useSelector, useDispatch } from 'react-redux'
import { selectCurrentProject, selectProjectSidebarOpen, setModal, setProjectSidebarOpen } from '../../redux/application-slice'
import { useGetProjectsQuery, useUpdateProjectOrderMutation } from '../../redux/api-slice'
import ProjectButton from './project-button'
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/outline'
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd'
import { Project } from '../../shared/interfaces/project/project.interface'
import { MODAL_TYPES } from '../modals/modal-controller'

interface OrderedProject extends Project {
    sort_index: number
}

export default function ProjectSelectorDrawer() {

    const projectSidebarOpen = useSelector(selectProjectSidebarOpen)
    const { currentData: projects, isLoading } = useGetProjectsQuery()
    const [orderedProjects, setOrderedProjects] = useState<OrderedProject[]>([])
    const [updateProjectOrder] = useUpdateProjectOrderMutation()
    const [search, setSearch] = useState('')
    const dispatch = useDispatch()
    const currentProject = useSelector(selectCurrentProject)

    const close = useCallback(() => {
        if (!currentProject) return
        dispatch(setProjectSidebarOpen(false))
    }, [currentProject, dispatch])

    useEffect(() => {
        setOrderedProjects(projects?.map((project, index) => ({ ...project, sort_index: index })) ?? [])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects])

    const draggableProjects = React.useMemo(() => {
        if (isLoading) {
            return Array(3).fill(0).map((_, i) => (
                <Skeleton key={i} height={30} mb="sm" radius="sm" />
            ))
        }

        const filteredProjects = orderedProjects?.filter(project => project.title?.toLowerCase().includes(search.toLowerCase()))
        if (!orderedProjects?.length) {
            return <div className="text-gray-500 text-center mt-4">No projects yet. Create your first project to get started!</div>
        }
        if (!filteredProjects?.length) {
            return <div className="text-gray-500 text-center mt-4">No projects match your search</div>
        }
        return filteredProjects.map((orderedProject, index) => (
            <ProjectButton
                key={orderedProject.id}
                project={orderedProject}
                sortIndex={index}
                isDraggingDisabled={!!search}
                dragDisabledMessage="Reordering is disabled while searching"
            />
        ))
    }, [orderedProjects, search, isLoading])

    const onDragEnd = useCallback((result: DropResult) => {
        if (search) return // Prevent reordering while searching

        const newOrder = [...orderedProjects]
        const [removed] = newOrder.splice(result.source.index, 1)
        newOrder.splice(result.destination?.index || 0, 0, removed)

        setOrderedProjects(newOrder)
        updateProjectOrder(Object.fromEntries(
            newOrder.map((project, index) => [
                project.id ?? 0,
                newOrder.length - 1 - index
            ])
        ))
    }, [search, orderedProjects, updateProjectOrder])

    const createNewProject = useCallback(() => {
        dispatch(setModal({ modal: MODAL_TYPES.CREATE_PROJECT }))
    }, [dispatch])

    return (
        <Drawer
            opened={projectSidebarOpen}
            onClose={close}
            size="lg"
            title="Projects"
            closeOnClickOutside={!!currentProject}
            closeOnEscape={!!currentProject}
            withCloseButton={!!currentProject}
        >
            <div className="flex mb-4 mt-1 gap-2" data-testid="project-selector-drawer-header">
                <TextInput
                    placeholder="Search projects"
                    leftSection={<MagnifyingGlassIcon className="w-4 h-4 text-gray-500" />}
                    value={search}
                    onChange={(event) => setSearch(event.currentTarget.value)}
                    radius="sm"
                    className="flex-1"
                />
                <Button leftSection={<PlusIcon className="w-4 h-4 text-gray-500" />} variant="default" onClick={createNewProject}>
                    Create a New Project
                </Button>
            </div>
            <div className="flex flex-col">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="dnd-list" direction="vertical">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {draggableProjects}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </Drawer>
    )
}
