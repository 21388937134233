import React from 'react'
import { useSelector } from 'react-redux'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline'
import { setWorkflowSidebarOpen, selectWorkflowSidebarOpen } from '../../redux/application-slice'
import { useDispatch } from 'react-redux'
import { Tooltip } from '@mantine/core'

export const DocumentViewerCloseWorkflowSidebar: React.FC = () => {
    const workflowSidebarOpen = useSelector(selectWorkflowSidebarOpen)
    const dispatch = useDispatch()

    const onClick = () => {
        dispatch(setWorkflowSidebarOpen(!workflowSidebarOpen))
    }

    return (
        <Tooltip label={workflowSidebarOpen ? "Close sidebar" : "Open sidebar"} openDelay={2000}>
            <button
                type="button"
                className="rounded p-1 mr-2 text-gray-500 hover:bg-gray-200 hover:text-gray-700"
                onClick={onClick}
                aria-label={workflowSidebarOpen ? "Close sidebar" : "Open sidebar"}
            >
                {workflowSidebarOpen ? (
                    <ChevronDoubleRightIcon className="h-5 w-5" />
                ) : (
                    <ChevronDoubleLeftIcon className="h-5 w-5" />
                )}
            </button>
        </Tooltip>
    )
}
