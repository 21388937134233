import React, { useCallback, useMemo, useState } from 'react'
import { ProjectDocumentMetadata } from '../../shared/interfaces/project/document/document.interface'

import { Combobox } from '@headlessui/react'
import { ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/20/solid'

interface DocumentListboxProps {
  documents?: ProjectDocumentMetadata[]
  selectedDocuments: ProjectDocumentMetadata[]
  setSelectedDocuments: (d: ProjectDocumentMetadata[]) => void
  noDefault?: boolean
  customClass?: string
  customWidth?: string
}

const DocumentListboxMulti: React.FC<DocumentListboxProps> = ({
  documents,
  selectedDocuments,
  setSelectedDocuments,
}) => {
  const [query, setQuery] = useState('')

  const onDocumentsSelect = useCallback(
    (selectedDocuments: number[]) => {
      setSelectedDocuments(
        documents?.filter((d) => selectedDocuments.includes(d.id)) ?? []
      )
    },
    [documents, setSelectedDocuments]
  )

  const filteredDocuments = useMemo(() => {
    return (
      documents?.filter((d) =>
        d.title.toLowerCase().includes(query.toLowerCase())
      ) ?? []
    )
  }, [documents, query])

  const onClearDocumentsFilter = useCallback(() => {
    setSelectedDocuments([])
  }, [setSelectedDocuments])

  return (
    <Combobox
      as="div"
      value={selectedDocuments.map((d) => d.id)}
      multiple={true}
      onChange={onDocumentsSelect}
    >
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-0.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          placeholder={
            selectedDocuments.length > 0
              ? `${selectedDocuments.length} documents`
              : 'All documents'
          }
        />
        {selectedDocuments.length > 0 && (
          <button
            className="absolute right-6 top-0 z-10 flex h-full items-center"
            onClick={onClearDocumentsFilter}
          >
            <XMarkIcon className="h-5 w-5" />
          </button>
        )}
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pl-8 focus:outline-none">
          <ChevronUpDownIcon
            className="h-4 w-4 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        <Combobox.Options className="fixed z-10 mt-1 max-h-96 w-full max-w-[22rem] divide-y overflow-y-scroll rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {filteredDocuments.map((document) => (
            <Combobox.Option
              key={document.id}
              value={document.id}
              className={`relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-indigo-600 hover:text-white ${
                selectedDocuments.find((d) => d.id === document.id)
                  ? 'bg-indigo-600 text-white'
                  : ''
              }`}
            >
              {document.title}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  )
}

export default DocumentListboxMulti
