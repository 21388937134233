/* eslint-disable @typescript-eslint/no-unused-vars */
import { Popover } from '@headlessui/react'
import React, { useEffect, useState } from 'react'
import { Revision } from '../../shared/interfaces/project/document/revision/revision.interface'
import { usePopper } from 'react-popper'
import { useGetRevisionHistoryQuery } from '../../redux/api/api-revisions-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import { format } from 'date-fns'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { useGetUserProfileQuery } from '../../redux/api-slice'

interface RevisionHistoryPopoverProps {
  revision: Revision
}

const RevisionHistoryPopover: React.FC<RevisionHistoryPopoverProps> = ({
  revision,
}) => {
  const [buttonRef, setButtonRef] = useState<HTMLElement | null>(null)
  const [panelRef, setPanelRef] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(buttonRef, panelRef)
  const { data: user } = useGetUserProfileQuery(undefined)

  const { currentData, refetch } = useGetRevisionHistoryQuery(
    revision?.id?.toString() ?? skipToken
  )

  useEffect(() => {
    refetch()
  }, [revision, refetch])

  if (!user?.feature_flags?.revision_history) {
    return (
      <div className="flex items-center justify-end text-xs text-gray-400">{`${revision?.user_created?.first_name} ${revision?.user_created?.last_name} - ${format(revision?.date_created ? new Date(revision.date_created) : new Date(), 'HH:mm dd/MM/yyyy')}`}</div>
    )
  }

  return (
    <Popover className="relative flex justify-end">
      <Popover.Button
        tabIndex={-1}
        ref={setButtonRef}
        className={
          'flex items-center justify-end text-xs text-gray-400 hover:bg-gray-100'
        }
      >
        {`${revision?.user_created?.first_name} ${revision?.user_created?.last_name} - ${format(revision?.date_created ? new Date(revision.date_created) : new Date(), 'HH:mm dd/MM/yyyy')}`}
        <ChevronDownIcon className="h-4 w-4" />
      </Popover.Button>
      <Popover.Panel
        ref={setPanelRef}
        style={styles.popper}
        {...attributes.popper}
        className="absolute left-0 z-50 flex max-w-96 flex-col divide-y rounded border bg-white text-left text-sm shadow"
      >
        {currentData?.length === 0 && <div>No revision history</div>}
        {currentData?.map((history) => (
          <div
            className="flex flex-col items-start justify-between gap-2 p-2 text-left text-xs"
            key={history.id}
          >
            <div className="flex gap-2">
              {`${history.user.first_name} - ${format(history.date_created ? new Date(history.date_created) : new Date(), 'HH:mm dd/MM/yyyy')}`}
            </div>
            <div>
              {history.text === '' ? <i>Deleted all text</i> : history.text}
            </div>
          </div>
        ))}
      </Popover.Panel>
    </Popover>
  )
}

export default RevisionHistoryPopover
