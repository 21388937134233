import React, { useCallback } from 'react'
import { Project } from '../../shared/interfaces/project/project.interface'
import { ChevronUpDownIcon, Cog6ToothIcon, FolderIcon } from '@heroicons/react/24/outline'
import { ActionIcon, Text } from '@mantine/core'
import { setCurrentProject, setModal, setProjectSidebarOpen } from '../../redux/application-slice'
import { setCurrentPage } from '../../redux/viewer-slice'
import UseUrlNavigate from '../../hooks/use-url-navigate'
import { useDispatch } from 'react-redux'
import { MODAL_TYPES } from '../modals/modal-controller'
import { Draggable } from '@hello-pangea/dnd'

interface ProjectButtonProps {
    project: Project
    sortIndex: number
    isDraggingDisabled?: boolean
    dragDisabledMessage?: string
}

export default function ProjectButton({ project, sortIndex, isDraggingDisabled, dragDisabledMessage }: ProjectButtonProps) {

    const { navigateUrl } = UseUrlNavigate()
    const dispatch = useDispatch()

    const onProjectOpened = useCallback(() => {
        if (!project?.uuid) {
            return
        }
        navigateUrl(project?.uuid)
        dispatch(setProjectSidebarOpen(false))
        dispatch(setCurrentPage(1))
    }, [dispatch, project, navigateUrl])

    const onProjectSettingsClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        dispatch(setCurrentProject(project))
        dispatch(setModal({ modal: MODAL_TYPES.PROJECT_SETTINGS, project }))
    }, [dispatch, project])

    return (
        <Draggable key={project.id} index={sortIndex} draggableId={project.uuid ?? ''} isDragDisabled={isDraggingDisabled}>
            {(provided, snapshot) => (
                <button type="button" aria-label={`Navigate to ${project.title}`} data-testid={`project_${project.id}`} onClick={onProjectOpened} className="w-full flex justify-between bg-white items-center hover:bg-gray-100 rounded-md py-1 px-1 cursor-pointer select-none" ref={provided.innerRef} {...provided.draggableProps}>
                    <div className="flex items-center gap-2 overflow-hidden">
                        <div {...provided.dragHandleProps} title={isDraggingDisabled ? dragDisabledMessage : undefined}>
                            <ChevronUpDownIcon className={`w-4 h-4 ${isDraggingDisabled ? 'text-gray-300' : 'text-gray-500'}`} />
                        </div>
                        <FolderIcon className="w-6 h-6 text-gray-500 flex-shrink-0" />
                        <Text truncate="end" title={project.title}>{project.title}</Text>
                    </div>
                    <div className="flex items-center gap-2">
                        <ActionIcon variant="subtle" onClick={onProjectSettingsClick} data-testid={`project_${project.title}_options_menu`} aria-label={`Project ${project.title} Options Menu`}>
                            <Cog6ToothIcon className="w-6 h-6 text-gray-500" />
                        </ActionIcon>
                    </div>
                </button>
            )}
        </Draggable>
    )
}
