import React from 'react'
import { Tooltip } from '@mantine/core'

interface ProjectSettingsTitleSectionProps {
  projectName: string
  canEdit: boolean
  submitting: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onEnterPress: (e: React.KeyboardEvent) => void
  onUpdateProjectName: () => void
  hasChanged: boolean
}

const ProjectSettingsTitleSection: React.FC<
  ProjectSettingsTitleSectionProps
> = ({
  projectName,
  canEdit,
  submitting,
  onChange,
  onEnterPress,
  onUpdateProjectName,
  hasChanged,
}) => (
  <div>
    <label
      htmlFor="projectTitle"
      className="block text-sm font-medium text-gray-700"
    >
      Project Title
    </label>
    <div className="mt-1 flex gap-2">
      <Tooltip
        label="You do not have permissions to perform this action"
        disabled={canEdit}
        position="top"
      >
        <div className="flex-1">
          {' '}
          {/* Wrapper div for input tooltip */}
          <input
            type="text"
            id="projectTitle"
            data-testid="project-settings-title-input"
            className={`block w-full rounded-md border-gray-300 shadow-sm
              ${canEdit ? 'bg-white' : 'bg-gray-100'}
              focus:border-blue-500 focus:ring-blue-500 sm:text-sm`}
            value={projectName}
            onChange={onChange}
            onKeyDown={onEnterPress}
            disabled={!canEdit}
            placeholder="Enter project name"
          />
        </div>
      </Tooltip>
      <Tooltip
        label="You do not have permissions to perform this action"
        disabled={canEdit}
        position="top"
      >
        <button
          type="button"
          disabled={!canEdit || submitting || !hasChanged}
          onClick={onUpdateProjectName}
          className={`inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm 
        ${
          canEdit && !submitting && hasChanged
            ? 'bg-blue-600 text-white hover:bg-blue-500'
            : 'cursor-not-allowed bg-gray-200 text-gray-500'
        }`}
        >
          {submitting ? 'Updating...' : 'Rename'}
        </button>
      </Tooltip>
    </div>
  </div>
)

export default ProjectSettingsTitleSection
