import { useEffect } from 'react';

const ChatwootWidget = () => {
    useEffect(() => {
        const BASE_URL = 'https://app.chatwoot.com';

        // Create the script element
        const script = document.createElement('script');
        script.src = `${BASE_URL}/packs/js/sdk.js`;
        script.defer = true;
        script.async = true;

        // Append the script to the document head
        document.head.appendChild(script);

        // Once the script is loaded, initialize Chatwoot
        script.onload = () => {
            window.chatwootSettings = {
                hideMessageBubble: true,
                position: 'right',
                locale: 'en',
                useBrowserLanguage: false,
                type: 'standard',
                darkMode: 'auto',
            };

            window.chatwootSDK.run({
                websiteToken: process.env.REACT_APP_CHATWOOT_TOKEN ?? '',
                baseUrl: BASE_URL,
            });
        };

        // Event listener for the widget toggle
        const handleWidgetInit = () => {
            if (window.$chatwoot) {
                window.$chatwoot.toggle('open');
                window.productFruits?.api.inAppCenter.close();
            }
        };
        window.addEventListener('productfruits_button_ext_widget_init', handleWidgetInit);

        // Cleanup function
        return () => {
            document.head.removeChild(script);
            window.removeEventListener('productfruits_button_ext_widget_init', handleWidgetInit);
        };
    }, []);

    return null;
};

export default ChatwootWidget;
