import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import { Paper } from '@mantine/core';
import React, { useEffect, useState } from 'react';

const HelpButton = () => {
    const [productFruitsReady, setProductFruitsReady] = useState(false);

    useEffect(() => {
        const handleProductFruitsReady = () => {
            if (window.productFruits) {
                setProductFruitsReady(true);
            }
        };

        // Add event listener for the 'productfruits_ready' event
        window.addEventListener('productfruits_ready', handleProductFruitsReady);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('productfruits_ready', handleProductFruitsReady);
        };
    }, []); // Empty dependency array ensures this runs once on mount

    const onClick = () => {
        if (productFruitsReady && window.productFruits) {
            window.productFruits.api.inAppCenter.show();
        }
        window.$chatwoot.toggle('close');
    }

    return <Paper
        radius="md"
        p="6px"
        bg="#ffffff00"
        className="group cursor-pointer border-2 border-[#ffffff00] transition-colors hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff] disabled:opacity-50 disabled:cursor-not-allowed"
        id="help-button"
        onClick={onClick}
        component="button"
        disabled={!productFruitsReady}
    >
        <div className="flex items-center justify-center text-[16px] text-gray-500 group-hover:text-black">
            <ChatBubbleLeftRightIcon className="mr-2 h-6 w-6 text-gray-500 group-hover:text-black" />
            Help
        </div>
    </Paper>;
};

export default HelpButton;
