import {
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline'
import React, { useCallback, useMemo, useState } from 'react'
import { ProjectDocumentMetadata } from '../../../shared/interfaces/project/document/document.interface'
import AIChatSourceButton from './ai-chat-source-button'
import { ChatSessionMessage } from '../../../shared/interfaces/chat/chat-history.interface'

interface AiChatSourceProps {
  chatMessage: ChatSessionMessage
  documents: ProjectDocumentMetadata[]
  message: string
}
const AiChatSource: React.FC<AiChatSourceProps> = ({
  chatMessage,
  documents,
  message,
}) => {
  const [sourcesExpanded, setSourcesExpanded] = useState(false)

  const onClickSourcesToggle = useCallback(() => {
    setSourcesExpanded((s) => !s)
  }, [])

  const hasStrikethroughText = useMemo(
    () =>
      chatMessage?.message_sources?.some(
        (source) => source?.document_segment?.metadata?.has_strikethrough_text
      ),
    [chatMessage?.message_sources]
  )

  return (
    <div className="flex flex-grow-0 flex-col gap-1 border-t bg-white p-1.5 pt-1 text-xs">
      <button
        onClick={onClickSourcesToggle}
        className="flex cursor-pointer hover:bg-gray-50"
      >
        Sources{' '}
        {!sourcesExpanded ? (
          <ChevronDownIcon className={'h-4 w-4'} />
        ) : (
          <ChevronUpIcon className={'h-4 w-4'} />
        )}
      </button>
      {hasStrikethroughText ? (
        <div className="flex items-center gap-1">
          <ExclamationTriangleIcon className="h-4 w-4" /> Some sources in this
          answer contain struck-through text. Please double-check sources.
        </div>
      ) : null}
      {sourcesExpanded && (
        <div
          className={'flex flex-col space-y-2 border-t border-gray-300 pt-1.5'}
        >
          <div>
            {chatMessage.message_sources?.map((source, sourceIndex) => {
              const sourceDocument = documents?.find(
                (d) => d.uuid === source.source_document_id
              )
              return (
                <div
                  key={`chat_source_${source.id}`}
                  className="mb-0.5 flex items-center"
                >
                  <AIChatSourceButton
                    sourceDocument={sourceDocument}
                    source={source}
                    sources={chatMessage.message_sources ?? []}
                    sourceIndex={sourceIndex}
                  />
                  <div className="ml-2">
                    {sourceDocument?.title} - pg. {source.source}
                  </div>
                </div>
              )
            })}
          </div>
          {chatMessage?.filtered_documents?.length ? (
            <div>
              <div className="font-bold">Filtered Documents:</div>
              {chatMessage?.filtered_documents?.map((projectDocument) => {
                return (
                  <div key={projectDocument.uuid}>{projectDocument.title}</div>
                )
              })}
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default AiChatSource
