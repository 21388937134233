import React, { useMemo } from 'react'
import { ProductFruits, useProductFruitsApi } from 'react-product-fruits'
import { useGetUserProfileQuery } from '../../redux/api-slice'

const ProductFruitsComponent = () => {
    const { data: profile } = useGetUserProfileQuery()

    const userInfo = useMemo(() => {
        if (!profile || !profile.product_fruits_hmac || !profile.uuid) {
            return null;
        }

        return {
            username: profile.uuid,
            email: profile.email,
            hmac: {
                hash: profile.product_fruits_hmac,
            },
            props: { hideInAppCenterLauncher: true }
        }
    }, [profile])

    const config = {
        disableBannersAutoMargin: false,
        disableLocationChangeDetection: false,
        hideInAppCenterLauncher: true,
    }

    useProductFruitsApi(api => {
        api.inAppCenter.attachToCustomElement(document.getElementById('help-button'))
    }, []);

    if (!profile || !userInfo) {
        return null;
    }

    return <ProductFruits
        workspaceCode={process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE ?? ''}
        language="en"
        user={userInfo}
        config={config}
    />

}

export default ProductFruitsComponent
