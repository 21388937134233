import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../redux/application-slice'
import { Divider, Paper, Text, Tooltip } from '@mantine/core'
import {
  BoltIcon,
  CheckCircleIcon,
  ClipboardDocumentIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline'
import { PanelPopover } from '../layout/panel-popover'
import {
  useGetDocumentsListByProjectQuery,
  useGetUserProfileQuery,
} from '../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import { ProjectDocumentMetadata } from '../../shared/interfaces/project/document/document.interface'
import DocumentListboxMulti from '../document-listbox/document-listbox-multi'
import RiskReviewListButton from '../workflows/risk-review/risk-review-list-button'
import { RiskStatus } from '../../shared/interfaces/project/risk/risk-inteface'
import {
  useGetProjectRiskQuery,
  useGetRiskListFiltersQuery,
} from '../../redux/api/project-risk-api-slice'
import {
  selectRiskWorkflowFilterState,
  setRiskWorkflowFilterState,
} from '../../redux/workflow-slice'
import {
  selectRiskListSearchQuery,
  selectSelectedDocuments,
  setRiskListSearchQuery,
  setSelectedDocuments,
} from '../../redux/risks-search-slice'
import clsx from 'clsx'

interface PanelContentProps {
  icon: React.ElementType
  text: string
  InnerContent: React.ReactElement
}

const PanelContent: React.FC<PanelContentProps> = ({
  icon: Icon,
  text,
  InnerContent,
}) => {
  return (
    <div className="group p-3">
      <div className="mb-2 flex items-center justify-start">
        <Icon className="mr-2 h-5 w-5 text-[#717885] group-hover:text-black" />
        <Text
          size="md"
          fw="500"
          c="#717885"
          className="transition-colors group-hover:!text-black"
        >
          {text}
        </Text>
      </div>
      <Divider my="8px" c="#717885" />
      {InnerContent}
    </div>
  )
}

const SearchPanelContent = ({ query }: { query: string }) => {
  const dispatch = useDispatch()
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setRiskListSearchQuery(e.target.value))
    },
    [dispatch]
  )
  return (
    <PanelContent
      icon={MagnifyingGlassIcon}
      text="Search by Risk Name"
      InnerContent={
        <input
          placeholder="Enter your search query here"
          value={query}
          onChange={onChange}
          className="w-full rounded-md border border-gray-300 p-1.5 text-sm text-gray-500 placeholder-gray-700 focus:border-blue-500"
        />
      }
    />
  )
}

export const ActiveDot = ({ active }: { active: boolean }) => (
  <>
    {active ? (
      <Tooltip label="Active Filter Applied">
        <span className="relative mb-4 flex  h-1 w-1 transition-all duration-300">
          <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75"></span>
          <span className="relative inline-flex h-1 w-1 rounded-full bg-blue-500"></span>
        </span>
      </Tooltip>
    ) : (
      <span className="relative mb-4 flex  h-1 w-1 transition-all duration-300">
        <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-none opacity-0"></span>
        <span className="relative inline-flex h-1 w-1 rounded-full bg-none opacity-0"></span>
      </span>
    )}
  </>
)

export const RiskReviewHeader: React.FC = () => {
  const navigate = useNavigate()
  const currentDocument = useSelector(selectCurrentDocument)
  const currentProject = useSelector(selectCurrentProject)
  const { data: riskLists, isLoading: riskListsLoading } =
    useGetRiskListFiltersQuery(currentProject?.id ?? skipToken)

  const { data: currentUser } = useGetUserProfileQuery(undefined)
  const canAccessCustomRiskLists = currentUser?.feature_flags?.custom_risk_lists

  const handleSetSelectedDocuments = (
    docs: ProjectDocumentMetadata[] | null
  ) => {
    dispatch(setSelectedDocuments(docs))
  }

  const { currentData: documents } = useGetDocumentsListByProjectQuery(
    currentProject ? { projectId: currentProject?.id } : skipToken
  )

  // Filters
  const selectedRiskList = useSelector(
    (state: any) => state.riskListState.selectedRiskList
  )

  const [riskPollingInterval, setRiskPollingInterval] = useState<
    number | undefined
  >(undefined)

  const riskWorkflowFilterState = useSelector(selectRiskWorkflowFilterState)
  const { currentData: projectRisks } = useGetProjectRiskQuery(
    riskWorkflowFilterState?.projectUUID
      ? {
          projectUUID: riskWorkflowFilterState?.projectUUID,
          status: riskWorkflowFilterState?.status,
          sort: riskWorkflowFilterState?.sort,
        }
      : skipToken,
    {
      pollingInterval: riskPollingInterval,
    }
  )

  useEffect(() => {
    if (!projectRisks || projectRisks?.some((r) => r.processing_status === 0)) {
      setRiskPollingInterval(5000)
    } else {
      setRiskPollingInterval(undefined)
    }
  }, [projectRisks])

  const goToCustomRisks = () => {
    if (currentProject) {
      navigate(
        `/${currentProject.uuid}/riskreview/${currentDocument?.uuid ?? 'error'}/customrisklists`
      )
    }
  }

  // For conditionally rendering active dot for Search Popover Button
  const riskListSearchQuery = useSelector(selectRiskListSearchQuery)
  // const riskListSort = riskWorkflowFilterState.sort ?? ''
  const dispatch = useDispatch()
  const onClickFilterApproved = useCallback(() => {
    const { status, ...rest } = riskWorkflowFilterState
    dispatch(
      setRiskWorkflowFilterState({
        status: RiskStatus.APPROVED,
        ...rest,
      })
    )
  }, [dispatch, riskWorkflowFilterState])

  const onClickFilterNotApproved = useCallback(() => {
    const { status, ...rest } = riskWorkflowFilterState
    dispatch(
      setRiskWorkflowFilterState({
        status: RiskStatus.NOT_APPROVED,
        ...rest,
      })
    )
  }, [dispatch, riskWorkflowFilterState])

  const onClickFilterNeedsReview = useCallback(() => {
    const { status, ...rest } = riskWorkflowFilterState
    dispatch(
      setRiskWorkflowFilterState({
        status: RiskStatus.NEEDS_REVIEW,
        ...rest,
      })
    )
  }, [dispatch, riskWorkflowFilterState])

  const onClickFilterAllRisks = useCallback(() => {
    const { status, ...rest } = riskWorkflowFilterState
    dispatch(
      setRiskWorkflowFilterState({
        status: undefined,
        ...rest,
      })
    )
  }, [dispatch, riskWorkflowFilterState])

  const FilterApprovalStatusButton = ({
    name,
    onClick,
    dataTestId,
    active,
  }: {
    name: string
    onClick: () => void
    dataTestId: string
    active: boolean
  }) => (
    <button
      data-testId={dataTestId}
      onClick={onClick}
      className={clsx(
        'flex w-full justify-start rounded px-3 py-2 text-left hover:bg-gray-100',
        { 'bg-gray-100': active }
      )}
    >
      {name}
    </button>
  )

  const selectedDocuments = useSelector(selectSelectedDocuments)

  const FilterContent = () => {
    return !riskLists || riskListsLoading ? (
      <div>Loading...</div>
    ) : (
      <div className="max-h-96 overflow-y-auto">
        <RiskReviewListButton projectRiskList={null} />
        {riskLists?.map((list) => (
          <RiskReviewListButton key={list.id} projectRiskList={list} />
        ))}
        <div className="my-2 mt-4 flex items-center justify-start">
          <CheckCircleIcon className="mr-2 h-5 w-5 text-[#717885] group-hover:text-black" />
          <Text
            size="md"
            fw="500"
            c="#717885"
            className="transition-colors group-hover:!text-black"
          >
            Filter by Approval Status
          </Text>
        </div>
        <Divider my="8px" c="#717885" />

        <FilterApprovalStatusButton
          name="All Risks"
          onClick={onClickFilterAllRisks}
          dataTestId="filter-status-all-button"
          active={riskWorkflowFilterState?.status === undefined}
        />
        <FilterApprovalStatusButton
          name="Approved"
          onClick={onClickFilterApproved}
          dataTestId="filter-status-approved-button"
          active={riskWorkflowFilterState?.status === RiskStatus.APPROVED}
        />
        <FilterApprovalStatusButton
          name="Not Approved"
          onClick={onClickFilterNotApproved}
          dataTestId="filter-status-not-approved-button"
          active={riskWorkflowFilterState?.status === RiskStatus.NOT_APPROVED}
        />
        <FilterApprovalStatusButton
          name="Needs Review"
          onClick={onClickFilterNeedsReview}
          dataTestId="filter-status-needs-review-button"
          active={riskWorkflowFilterState?.status === RiskStatus.NEEDS_REVIEW}
        />
      </div>
    )
  }

  return (
    <div className="flex w-full justify-between px-2 pr-3">
      <div className="flex w-full items-center justify-start">
        <PanelPopover
          menuPanel={<SearchPanelContent query={riskListSearchQuery} />}
          width={280}
          menuTarget={
            <Tooltip label="Search within Risk Review" position="bottom">
              <Paper
                data-testid="risk-review-search-button"
                radius="md"
                p="6px"
                bg="#ffffff00"
                className="group !flex cursor-pointer border-2 border-[#ffffff00] transition-colors hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]"
              >
                <div className="flex items-center justify-center text-sm text-gray-500 group-hover:text-black">
                  <MagnifyingGlassIcon className="mr-2 h-4 w-4 text-gray-500 group-hover:text-black" />
                  Search
                </div>
                <ActiveDot active={riskListSearchQuery !== ''} />
              </Paper>
            </Tooltip>
          }
        />
      </div>
      <div className="flex items-center justify-end">
        <>
          <PanelPopover
            menuPanel={
              <PanelContent
                icon={FunnelIcon}
                text="Filter by List"
                InnerContent={<FilterContent />}
              />
            }
            width={280}
            menuTarget={
              <Paper
                data-testid="risk-review-filter-button"
                radius="md"
                p="6px"
                bg="#ffffff00"
                className="group !flex cursor-pointer border-2 border-[#ffffff00] transition-colors hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]"
              >
                <div className="flex items-center justify-center text-sm text-gray-500 group-hover:text-black">
                  <FunnelIcon className="mr-2 h-4 w-4 text-gray-500 group-hover:text-black" />
                  Filter
                </div>
                <ActiveDot
                  active={
                    selectedRiskList != null ||
                    riskWorkflowFilterState?.status !== undefined
                  }
                />
              </Paper>
            }
          />
        </>
        {/* TODO: This is the entry point for Sorting the Risk List, which is being disabled (for now) 
        because we need to integrate category-level grouping, and sorting by risk name will make it hard
         to keep the top-level section sorting and the in-category risk sorting clear and simple */}
        {/* <PanelPopover
          menuPanel={
            <PanelContent
              icon={ArrowsUpDownIcon}
              text="Sort Risk List"
              InnerContent={
                <div>
                  <RiskReviewSortListBox />
                </div>
              }
            />
          }
          width={280}
          menuTarget={
            <Paper
              data-testid="risk-review-sort-button"
              radius="md"
              p="6px"
              bg="#ffffff00"
              className="group !flex cursor-pointer border-2 border-[#ffffff00] transition-colors hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]"
            >
              <div className="flex items-center justify-center text-sm text-gray-500 group-hover:text-black">
                <ArrowsUpDownIcon className="mr-2 h-4 w-4 text-gray-500 group-hover:text-black" />
                Sort
              </div>
              <ActiveDot active={riskListSort !== ''} />
            </Paper>
          }
        /> */}
        <PanelPopover
          menuPanel={
            <PanelContent
              icon={ClipboardDocumentIcon}
              text="Specify Documents to Include"
              InnerContent={
                <div>
                  <DocumentListboxMulti
                    selectedDocuments={selectedDocuments ?? []}
                    documents={documents}
                    setSelectedDocuments={handleSetSelectedDocuments}
                  />
                </div>
              }
            />
          }
          width={320}
          menuTarget={
            <Paper
              data-testid="risk-review-documents-button"
              radius="md"
              p="6px"
              bg="#ffffff00"
              className="group !flex !cursor-pointer border-2 border-[#ffffff00] transition-colors hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]"
            >
              <div className="flex items-center justify-center text-sm text-gray-500 group-hover:text-black">
                <ClipboardDocumentIcon className="mr-2 h-4 w-4 text-gray-500 group-hover:text-black" />
                Documents
              </div>
              <ActiveDot active={(selectedDocuments ?? []).length > 0} />
            </Paper>
          }
        />
        {canAccessCustomRiskLists ? (
          <Link to="#" onClick={goToCustomRisks}>
            <Paper
              data-testid="risk-review-custom-risks-button"
              radius="md"
              p="6px"
              bg="#ffffff00"
              className="group cursor-pointer border-2 border-[#ffffff00] transition-colors hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]"
            >
              <div className="flex  w-[115px] items-center justify-center text-sm text-gray-500 group-hover:text-black">
                <BoltIcon className="mr-2 h-4 w-4 text-gray-500 group-hover:text-black" />
                Custom Risks
              </div>
            </Paper>
          </Link>
        ) : null}
      </div>
    </div>
  )
}
