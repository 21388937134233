import React, { useCallback, useMemo } from 'react'
import { useAssignUserRoleMutation, useGetUserProfileQuery } from '../../redux/api-slice'
import { useGetOrganisationQuery } from '../../redux/api/organisation-api-slice'
import { useGetOrganisationMembersQuery } from '../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import { UserRoles } from '../../shared/interfaces/user/permissions.inteface'
import { Tooltip } from 'react-tooltip'

const OrganisationTeamMembers: React.FC = () => {
  const { data: userProfile } = useGetUserProfileQuery(undefined)

  const { data: organisationMembers } = useGetOrganisationMembersQuery(
    userProfile?.organisation?.id ?? skipToken
  )
  const { data: organisationData } = useGetOrganisationQuery(
    userProfile?.organisation?.id ?? skipToken
  )
  const [assignUserRole] = useAssignUserRoleMutation()

  const getUserOrganisationRole = useCallback((member) => {
    if (member.organisation_roles.includes(UserRoles.ORGANISATION_ADMIN_ROLE)) {
      return UserRoles.ORGANISATION_ADMIN_ROLE
    } else if (
      member.organisation_roles.includes(UserRoles.ORGANISATION_MEMBER_ROLE)
    ) {
      return UserRoles.ORGANISATION_MEMBER_ROLE
    } else {
      return 'unknown'
    }
  }, [])

  const organisationRoles = useCallback(
    (email: string | undefined): string[] => {
      if (!email || !organisationMembers) {
        return []
      }

      const memberProfile = organisationMembers.find(
        (member) => member.email === email
      )

      if (!memberProfile) {
        return []
      }

      return memberProfile.organisation_roles
    },
    [organisationMembers]
  )

  const onRoleChange = useCallback(
    async (member, role) => {
      try {
        await assignUserRole({
          user_id: member.id,
          role: role,
          content_type: 'organisationmodel',
          object_id: userProfile?.organisation?.id ?? '',
        })
      } catch (error) {
        console.error('Failed to change role:', error)
      }
    },
    [assignUserRole, userProfile?.organisation?.id]
  )

  const canEditPermissions = useMemo(
    () =>
      organisationRoles(userProfile?.email).includes(
        UserRoles.ORGANISATION_ADMIN_ROLE
      ),
    [organisationRoles, userProfile?.email]
  )

  const getToolTipContent = useCallback(
    (member) => {
      if (member.email === userProfile?.email) {
        return 'Only your Organisation Admins can change your role'
      } else if (!canEditPermissions) {
        return 'You must be an Organisation Admin to change roles'
      }
      return ''
    },
    [canEditPermissions, userProfile?.email]
  )

  return (
    <div>
      <div className="py-4">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Team Members
        </h2>
        <p className="max-w-2xl text-sm leading-6 text-gray-500">
          in {organisationData?.name}
        </p>
      </div>
      <hr />

      <div className="space-y-2 py-4">
        <div>
          <div className="text-sm font-semibold leading-7 text-gray-900">
            Organisation Admin
          </div>
          <div className="mt-1 text-xs leading-6 text-gray-500">
            Admins can add and remove members from an organisation, and can
            access all projects created by members.
          </div>
        </div>
        <div>
          <div className="text-sm font-semibold leading-7 text-gray-900">
            Organisation Member
          </div>
          <div className="mt-1 text-xs leading-6 text-gray-500">
            Members can view organisation members, and can be added to
            organisation projects as well as create projects.
          </div>
        </div>
      </div>
      <hr />
      <div>
        {organisationMembers?.map((member) => (
          <div
            key={member.email}
            className="flex justify-between border-b py-4"
          >
            <div>
              <div className="text-sm font-semibold leading-6 text-gray-900">
                {member.first_name} {member.last_name}{' '}
                {member.email === userProfile?.email && '(You)'}
              </div>
              <div className="text-xs leading-5 text-gray-500">
                {member.email}
              </div>
            </div>
            <div>
              <div>
                <Tooltip id="role-dropdown-tooltip" />
                <select
                  id="role"
                  name="role"
                  className="mt-2 block w-72 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => onRoleChange(member, e.target.value)}
                  defaultValue={getUserOrganisationRole(member)}
                  disabled={
                    member.email === userProfile?.email || !canEditPermissions
                  }
                  data-tooltip-id="role-dropdown-tooltip"
                  data-tooltip-content={getToolTipContent(member)}
                >
                  <option value={UserRoles.ORGANISATION_ADMIN_ROLE}>
                    Organisation Admin
                  </option>
                  <option value={UserRoles.ORGANISATION_MEMBER_ROLE}>
                    Organisation Member
                  </option>
                </select>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default OrganisationTeamMembers
